<!-- 默认公共布局 -->
<template>
  <div class="download-layout">
    <common-header-transparent/>
    <div class="router-view">
      <div class="router-view-inner">
        <slot name="routerView"/>
      </div>
      <div class="back-top">
        <back-top/>
      </div>
    </div>
    <common-footer />
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="less" scoped>
//修改导航条样式
.download-layout {
  min-width: 1440px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  // 路由出口
  .router-view {
    flex: 1;
    background: #f5f5f5;
    box-sizing: border-box;
    .router-view-inner {
      width: 100%;
      flex: 1;
      box-sizing: border-box;
    }
    // 回到顶部
    .back-top{
    @apply fixed;
      bottom: 50px;
      right: 0;
    }
  }
}
</style>